import { render, staticRenderFns } from "./MultichainAccount.vue?vue&type=template&id=76470272&scoped=true&"
import script from "./MultichainAccount.vue?vue&type=script&lang=ts&"
export * from "./MultichainAccount.vue?vue&type=script&lang=ts&"
import style0 from "./MultichainAccount.vue?vue&type=style&index=0&id=76470272&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76470272",
  null
  
)

export default component.exports