import Dapp from "@/assets/lib/Dapp";
import { Batcher } from "@/lib/eth/Batcher";
import abi from "@/assets/data/BalanceToken.abi.json";
import BigNumber from "bignumber.js";
import { parseLocks } from "@/views/happs/locks/lockslib";
export class ChainToken {
    constructor(data) {
        Object.assign(this, data);
    }
}
export async function loadMultichainToken(contractAddress, network, addresses) {
    if (!contractAddress) {
        return;
    }
    let net = Dapp.getNetwork(network);
    let web3 = net.web3;
    let batcher = new Batcher(web3);
    batcher.setContract(abi, contractAddress);
    let argsList = addresses.map((it) => [it]);
    batcher.addObjectList("data", addresses.length, {
        address: {
            valueList: addresses,
        },
        balance: {
            method: "balanceOf",
            argsList,
            type: "bignumber",
        },
        availableBalance: {
            method: "availableBalanceOf",
            argsList,
            type: "bignumber",
        },
        unlocked: {
            method: "unlockedBalanceOf",
            argsList,
            type: "bignumber",
        },
        locksOf: {
            method: "locksOf",
            argsList,
            type: "bignumber",
        },
        allStakes: {
            method: "allStakes",
            argsList,
            convert(val, obj) {
                if (val) {
                    obj.allStakes = val.map((it) => {
                        return {
                            value: new BigNumber(it[0].toString()),
                            expiration: it[1].toNumber(),
                            time: it[2].toNumber(),
                        };
                    });
                }
            },
        },
    });
    let result = await batcher.execute();
    let data = result.data.map((it) => {
        let token = new ChainToken(it);
        if (token.allStakes) {
            token.stake = token.allStakes.reduce((acc, it) => {
                return acc.plus(it.value);
            }, new BigNumber(0));
        }
        token.lockData = parseLocks(token.locksOf);
        return token;
    });
    return data;
}
