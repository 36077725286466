import InfoField from "../locks/InfoField.vue";
import TokenAmount from "../../../components/TokenAmount.vue";
import Eip1193Mixin from "@/components/dumbapp/executor/Eip1193Mixin";
export default {
    name: "MultichainToken",
    components: { TokenAmount, InfoField },
    mixins: [Eip1193Mixin],
    inject: ["token"],
    props: {
        acc: {
            type: Object,
        },
        chains: {
            type: Array,
        },
        symbol: String,
        tokenData: Object
    },
    data() {
        return {};
    },
    computed: {
        networkName() {
            return this.acc.token.network.networkName;
        },
        otherChains() {
            return this.chains.filter((it) => it.chainId !== this.acc.token.network.chainId);
        },
    },
    methods: {
        addToMetaMask() {
            let logo = null;
            if (this.tokenData.logo) {
                logo = "https://cdn.blockwell.ai" + this.tokenData.logo;
            }
            this.addToken(this.acc.token.network, this.acc.token.address, this.acc.token.symbol, this.acc.token.decimals, logo);
        },
        swap(toChain) {
            this.$emit("swap", {
                from: this.acc.token.network,
                to: toChain
            });
        }
    },
};
