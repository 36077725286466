import Bwns from "@/lib/api/Bwns";
import Dapp from "@/assets/lib/Dapp";
import { loadFiles } from "@/views/happs/book/lib/booklib";
import BigNumber from "bignumber.js";
import moment from "moment";
import { getNetwork } from "@/assets/lib/networks";
import { omit, sortByPath } from "rambdax";
import { Chain } from "@blockwell/chain-client";
import abi from "@/assets/data/BalanceToken.abi.json";
const bwns = new Bwns();
class StakingBase {
}
export class StakingSettings extends StakingBase {
}
export class Staking extends StakingBase {
    constructor(settings) {
        super();
        Object.assign(this, omit(["chain"], settings));
        this.chainId = getNetwork(settings.chain).chainId;
        if (settings.lockupRates) {
            this.lockupRates = sortByPath("lockupDays", settings.lockupRates);
            this.lockupRates.reverse();
        }
        if (settings.time.includes(" ")) {
            this.period = "weekly";
            this.time = moment.utc(settings.time, ["ddd H:mm", "dddd H:mm"]).local();
            if (this.time.isBefore()) {
                this.time.add(7, "days");
            }
        }
        else {
            this.period = "daily";
            this.time = moment.utc(settings.time, ["H:mm"]).local();
            if (this.time.isBefore()) {
                this.time.add(1, "days");
            }
        }
    }
}
let stakingNs;
async function getStakingBook(name) {
    if (!stakingNs) {
        stakingNs = await bwns.resolve(name, "book");
    }
    return stakingNs;
}
export async function getMultichainStaking(name) {
    let ns = await getStakingBook(name);
    let network = Dapp.getNetwork(ns.network);
    let files = await loadFiles({ web3: network.web3, network, address: ns.address }, "Settings", true);
    let settings = JSON.parse(files[0].data);
    let staking = new Staking(settings);
    let delay = await Chain.readString(settings.chain, settings.address, abi, "unstakingDelay", [], 15 * 60 * 1000);
    if (delay) {
        staking.unstakingDelay = parseInt(delay);
    }
    return staking;
}
export function getRateForStake(staking, data) {
    let days = data.time / (24 * 60 * 60);
    let rate = staking.lockupRates.find(rate => rate.lockupDays <= days);
    if (rate) {
        return rate;
    }
    return {
        rate: staking.rate,
        lockupDays: 0
    };
}
export function nextStakingReward(staking, data) {
    let nextTotal = new BigNumber(0);
    for (let it of data) {
        let rateData = getRateForStake(staking, it);
        let rate = new BigNumber(rateData.rate).div(100);
        if (staking.period === "daily") {
            rate = rate.div(365);
        }
        else {
            rate = rate.div(52);
        }
        nextTotal = nextTotal.plus(it.value.times(rate).dp(0));
    }
    return nextTotal;
}
