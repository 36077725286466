import { PrimeSwapSettings } from "@blockwell/book/lib/prime";
export const PrimeAllSettings = {
    ...PrimeSwapSettings,
    whitelabel: {
        folder: "Whitelabel",
        file: "style",
        type: null
    },
    staking: {
        folder: "Staking",
        file: "settings",
        type: null
    }
};
