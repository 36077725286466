import Clipboard from "@/components/Clipboard.vue";
import Eip1193Mixin from "@/components/dumbapp/executor/Eip1193Mixin";
import DumbappPopover from "@/components/DumbappPopover.vue";
import FadeSlide from "@/components/FadeSlide.vue";
import Info from "@/components/Info.vue";
import Modal from "@/components/Modal.vue";
import TokenAmount from "@/components/TokenAmount.vue";
import InfoField from "@/views/happs/locks/InfoField.vue";
import InfoSection from "@/views/happs/locks/InfoSection.vue";
import { sendUnlock } from "@/views/happs/locks/lockslib";
import BigNumber from "bignumber.js";
import Vue from "vue";
import MultichainToken from "./MultichainToken.vue";
import { nextStakingReward, Staking } from "./multichain-staking";
import LoadingButton from "../../../components/LoadingButton.vue";
import DumbappModal from "../../../components/DumbappModal.vue";
import moment from "moment";
import { preciseDiff } from "@/lib/moment-precise-range";
import PendingSwapsList from "@/views/happs/nftswap/PendingSwapsList.vue";
import TokenLockView from "@/views/happs/multichain/TokenLockView.vue";
export default Vue.extend({
    name: "MultichainAccount",
    components: {
        TokenLockView,
        PendingSwapsList,
        DumbappModal,
        LoadingButton,
        MultichainToken,
        Modal,
        Info,
        InfoSection,
        InfoField,
        DumbappPopover,
        FadeSlide,
        TokenAmount,
        Clipboard,
    },
    mixins: [Eip1193Mixin],
    props: {
        acc: {
            type: Object,
        },
        symbol: String,
        staking: Staking,
        stakingFrom: Object,
        stakingTo: Object,
    },
    inject: ["token"],
    data() {
        return {
            expanded: false,
            showActivateWarning: false,
            showActivate: false,
            stakingLoading: null,
            stakingDumbapp: null,
            showStakingDumbapp: false,
        };
    },
    computed: {
        api() {
            return this.$store.getters["user/api"];
        },
        availableBalance() {
            let balance = new BigNumber(0);
            for (let token of this.acc.tokens) {
                balance = balance.plus(token.data.availableBalance);
            }
            return balance;
        },
        totalTokens() {
            let balance = new BigNumber(0);
            for (let token of this.acc.tokens) {
                balance = balance.plus(token.data.balance);
            }
            return balance.plus(this.totalStake);
        },
        usdBalance() {
            return null;
        },
        userAccount() {
            return this.$store.state.user.account;
        },
        isUserAccount() {
            return this.userAccount?.toLowerCase() === this.acc.address.toLowerCase();
        },
        chains() {
            return this.acc.tokens.map((it) => it.token.network);
        },
        totalStake() {
            let balance = new BigNumber(0);
            for (let token of this.acc.tokens) {
                balance = balance.plus(token.data.stake || 0);
            }
            return balance;
        },
        totalUnstakeable() {
            let total = new BigNumber(0);
            for (let token of this.acc.tokens) {
                if (token.data.allStakes) {
                    for (let stake of token.data.allStakes) {
                        if (stake.expiration < Date.now() / 1000) {
                            total = total.plus(stake.value);
                        }
                    }
                }
            }
            return total;
        },
        allStakes() {
            if (!this.staking) {
                return null;
            }
            let token = this.acc.tokens.find((it) => it.token.network.chainId === this.staking.chainId);
            return token.data.allStakes.map((it) => {
                return {
                    ...it,
                    expirationDate: moment.unix(it.expiration).local(),
                    startDate: moment.unix(it.expiration).subtract(it.time, "seconds").local(),
                };
            });
        },
        nextStakingReward() {
            if (!this.allStakes) {
                return null;
            }
            return nextStakingReward(this.staking, this.allStakes);
        },
        unstakingDelay() {
            if (this.staking && this.staking.unstakingDelay > 10) {
                return preciseDiff(moment(), moment().add(this.staking.unstakingDelay, "seconds"));
            }
            return null;
        },
        locks() {
            let locks = [];
            for (let token of this.acc.tokens) {
                if (token.data.lockData?.locks) {
                    locks = locks.concat(token.data.lockData.locks);
                }
            }
            return locks;
        },
        totalLocked() {
            let total = new BigNumber(0);
            for (let token of this.acc.tokens) {
                total = total.plus(token.data.lockData?.lockedTotal || 0);
            }
            return total;
        },
        stakingQuery() {
            let data = {};
            if (!this.staking) {
                data.stakeTime = "0";
            }
            return data;
        },
    },
    methods: {
        expand() {
            this.expanded = !this.expanded;
        },
        unlock() {
            sendUnlock(this.ethereumHolder.ethereum, this.acc.address, this.token.address).catch(console.error);
        },
        activateWarning() {
            this.showActivateWarning = true;
        },
        activate() {
            this.showActivateWarning = false;
            this.showActivate = true;
        },
        stake(days) {
            this.stakingLoading = days;
            let time = days === 0 ? 1 : days * 24 * 60 * 60;
            this.api
                .multichainStakeDumbapp(this.stakingFrom, this.stakingTo, time)
                .then((dumbapp) => {
                this.stakingDumbapp = dumbapp;
                this.showStakingDumbapp = true;
            })
                .finally(() => {
                this.stakingLoading = null;
            });
        },
        unstake() {
            this.stakingDumbapp = this.token.dumbapps[`${this.staking.chainId}-unstake`];
            this.showStakingDumbapp = true;
        },
        swap(data) {
            let dumbapp = this.token.dumbapps[`${data.from.chainId}-${data.to.chainId}`];
            if (dumbapp) {
                this.stakingDumbapp = dumbapp;
                this.showStakingDumbapp = true;
            }
        },
    },
    destroyed() { },
});
