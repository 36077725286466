import { render, staticRenderFns } from "./MultichainToken.vue?vue&type=template&id=615691cd&scoped=true&"
import script from "./MultichainToken.vue?vue&type=script&lang=ts&"
export * from "./MultichainToken.vue?vue&type=script&lang=ts&"
import style0 from "./MultichainToken.vue?vue&type=style&index=0&id=615691cd&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "615691cd",
  null
  
)

export default component.exports