<template>
    <component :is="layoutComponent" no-messages size="full"
               :vars="cssVars"
               :icon="logo"
               :external="external"
    >
        <portal to="title">
            <img v-if="headingLogo" :src="headingLogo" :alt="name" class="heading-logo" />
            <template v-else>
                <img :src="logo" alt="" />
                <span>{{ name }}</span>
            </template>
        </portal>
        <template #heading-cta>
            <div class="heading-token-name">{{ name }} ({{ symbol }})</div>
            <div class="heading-token-address">
                <Clipboard :address="address" :network="net" />
            </div>
            <div class="add-buttons">
                <button class="btn btn-sm btn-info" type="button" @click="addTokenClick">
                    Add {{ symbol }} To MetaMask
                </button>
                <button
                    v-if="stakedAddress"
                    class="btn btn-sm btn-info"
                    type="button"
                    @click="addStakedTokenClick"
                >
                    Add {{ stakedSymbol }} To MetaMask
                </button>
            </div>
        </template>

        <div class="locks" ref="locks">
            <LockAccount
                v-for="it in accounts"
                :acc="it"
                :key="it.address"
                :staking="staking"
                :hard-staking="hardStaking"
                @remove="removeAddress($event)"
            />
        </div>

        <div class="add-addresses">
            <h3>Add Addresses</h3>
            <p>Add Wallet Addresses to view {{ symbol }} lockups</p>

            <p class="formulate-input-help">Paste in your address</p>
            <div class="address-input formulate-input" data-classification="text">
                <div class="address-input-wrapper">
                    <input
                        :id="$id('address')"
                        type="text"
                        placeholder="0x123..."
                        @keydown="keydown"
                        v-model="addressInput"
                    />
                    <button class="btn btn-sm btn-primary" type="button" @click="submitAddress">
                        Add Wallet
                    </button>
                </div>
            </div>
            <div class="add-metamask">
                <p>or you can</p>
                <button class="btn btn-sm btn-outline-metamask" @click="fromMetamask">
                    <img src="../../../assets/images/logos/metamask-fox.svg" height="24" alt="" />
                    Add From MetaMask
                </button>
            </div>
            <div v-if="!userAccount" class="add-metamask">
                <p>or</p>
                <button class="btn btn-sm btn-primary" @click="login">
                    Sign In to Blockwell Wallet
                </button>
            </div>
        </div>
        <Modal :show.sync="showMetamaskNotice">
            <p>Your browser does not have MetaMask enabled.</p>
            <p>
                On a desktop or laptop computer you can
                <a href="https://metamask.io/download.html" target="_blank"
                    >install MetaMask here</a
                >.
            </p>
            <p>Mobile versions of MetaMask are not supported at this time.</p>
            <button class="btn btn-sm btn-primary" @click="showMetamaskNotice = false">
                OK
            </button>
        </Modal>
        <Modal v-if="networkChange" :show.sync="wrongAddTokenNetwork">
            <p>MetaMask is connected to the wrong network.</p>
            <p>
                Change network to <strong>{{ networkChange.networkName }}</strong> and then
                click <em>Add {{ symbol }} To MetaMask</em> again.
            </p>
            <button class="btn btn-sm btn-primary" @click="wrongAddTokenNetwork = false">
                OK
            </button>
        </Modal>
        <portal to="footer">
            <div class="footer-resources">
                <h4 class="resources-heading">RESOURCES</h4>
                <ul class="resources-nav">
                    <li v-if="velvetLink" class="nav-item">
                        <a :href="velvetLink">{{name}} ({{symbol}}) On Blockwell Velvet</a>
                    </li>
                    <li class="nav-item">
                        <a :href="dumbappsLink">Dapps For {{name}} ({{symbol}})</a>
                    </li>
                </ul>
            </div>
        </portal>
    </component>
</template>

<script>
import { getNetwork } from "@/assets/lib/networks";
import Clipboard from "@/components/Clipboard";
import Eip1193Mixin from "@/components/dumbapp/executor/Eip1193Mixin";
import FadeSlide from "@/components/FadeSlide";
import Modal from "@/components/Modal";
import TokenAmount from "@/components/TokenAmount";
import WhitelabelHeading from "@/layouts/WhitelabelHeading";
import Heading from "@/layouts/Heading";
import { getPrice } from "@/lib/prices";
import LockAccount from "@/views/happs/locks/LockAccount";
import { loadLocks } from "@/views/happs/locks/lockslib";
import { clone } from "rambdax";
import { mapGetters, mapMutations, mapState } from "vuex";
import * as interval from "@/lib/interval";
import equal from "fast-deep-equal";

export default {
    components: { WhitelabelHeading, Modal, LockAccount, FadeSlide, TokenAmount, Clipboard },
    mixins: [Eip1193Mixin],
    props: {
        address: String,
        net: String,
        stakedAddress: String,
        stakedNet: String,
        stakedSymbol: String,
        name: String,
        symbol: String,
        decimals: Number,
        logo: String,
        headingLogo: String,
        icon: String,
        places: Number,
        dumbapp: String,
        whitelabel: Boolean,
        external: String,
        velvet: String,
        cssVars: {
            type: Object,
            default() {
                return {
                };
            },
        },
    },
    provide() {
        return {
            token: this.token,
        };
    },
    data() {
        return {
            addressInput: "",
            accounts: [],
            interval: null,
            metamaskRequest: false,
            staking: null,
            hardStaking: null,
            currentAddresses: null,
            showMetamaskNotice: false,
            token: {
                address: this.address,
                network: getNetwork(this.net),
                stakedAddress: this.stakedAddress,
                stakedNetwork: getNetwork(this.stakedNet),
                stakedSymbol: this.stakedSymbol,
                name: this.name,
                symbol: this.symbol,
                decimals: this.decimals,
                logo: this.logo,
                places: this.places,
                price: null,
                dumbapps: {},
            },
        };
    },
    computed: {
        ...mapGetters("locks", ["addresses"]),
        ...mapGetters("user", ["api", "loggedIn"]),
        userAccount() {
            return this.$store.state.user.account;
        },
        layoutComponent() {
            if (this.whitelabel) {
                return WhitelabelHeading;
            } else {
                return Heading;
            }
        },
        velvetLink() {
            if (this.velvet) {
                return `https://blockwell.ai/learn-tokens/token/${this.velvet}`;
            }
            return null;
        },
        dumbappsLink() {
            return `https://app.blockwell.ai/bapps?contract=${this.address}&display=grid`;
        }
    },
    watch: {
        addresses: {
            deep: true,
            immediate: true,
            handler(val) {
                if (!equal(val, this.currentAddresses)) {
                    this.currentAddresses = clone(val);
                    this.updateAddresses(val);
                }
            },
        },
        account(val) {
            if (this.metamaskRequest) {
                this.addAddress(val);
                this.metamaskRequest = false;
            }
        },
    },
    methods: {
        ...mapMutations("locks", ["remove_address", "add_address"]),
        addAddress(address) {
            this.add_address(address);

            let y = this.$refs.locks.getBoundingClientRect().top + window.pageYOffset - 60;
            window.scrollTo({ top: y, behavior: "smooth" });

            this.$router.replace({ query: { address } });
        },
        removeAddress(address) {
            this.remove_address(address);
            if (this.$route.query.address?.toLowerCase() === address.toLowerCase()) {
                this.$router.replace({ query: {} });
            }
        },
        submitAddress() {
            this.addAddress(this.addressInput);
            this.addressInput = "";
        },
        keydown(event) {
            if (event.key === "Enter") {
                this.submitAddress();
            }
        },
        updateAddresses(val) {
            let addresses = [].concat(val || []);

            if (this.userAccount) {
                if (!addresses.find((it) => it.toLowerCase() === this.userAccount.toLowerCase())) {
                    addresses.unshift(this.userAccount);
                }
            }
            if (this.$route.query.address) {
                if (
                    !addresses.find(
                        (it) => it.toLowerCase() === this.$route.query.address.toLowerCase()
                    )
                ) {
                    addresses.unshift(this.$route.query.address);
                }
            }

            if (addresses.length > 0) {
                Promise.all([
                    loadLocks(this.address, this.net, addresses),
                    loadLocks(this.stakedAddress, this.stakedNet, addresses),
                ])
                    .then(([locks, staked]) => {
                        let accounts = locks.data;
                        let staking = locks.staking;

                        if (staked) {
                            this.hardStaking = staked.staking;
                            let i = 0;
                            for (let it of staked.data) {
                                let account = accounts[i];
                                if (it.stakingRate) {
                                    account.softStakingRate = account.stakingRate;
                                    account.hardStakingRate = it.stakingRate;
                                    account.nfts = it.nfts;
                                }
                                if (it.balance.gt(0)) {
                                    account.staked = it;
                                }
                                ++i;
                            }
                        }

                        if (this.loggedIn) {
                            this.api.addToContractHistory(
                                this.$route.fullPath,
                                "Locks and Staking happ",
                                this.token.address,
                                this.token.network.networkId
                            );
                        }

                        this.accounts = accounts;
                        this.staking = staking;
                    })
                    .catch(console.error);
            } else {
                this.accounts = [];
            }
        },
        expand(account) {
            this.$set(account, "expanded", true);
        },
        clear() {
            if (this.interval !== null) {
                this.interval.clear();
                this.interval = null;
            }
        },
        fromMetamask() {
            if (this.account) {
                this.addAddress(this.account);
            } else {
                if (this.ethereumHolder.ethereum) {
                    this.metamaskRequest = true;
                    try {
                        this.connect();
                    } catch (err) {
                        console.error(err);
                        this.showMetamaskNotice = true;
                    }
                } else {
                    this.showMetamaskNotice = true;
                }
            }
        },
        login() {
            this.$bus.emit("login_request", {
                message: "",
            });
        },
        addTokenClick() {
            let logo = null;
            if (this.logo) {
                logo = "https://cdn.blockwell.ai" + this.logo;
            }
            this.addToken(this.net, this.address, this.symbol, this.decimals, logo);
        },
        addStakedTokenClick() {
            let logo = null;
            if (this.logo) {
                logo = "https://cdn.blockwell.ai" + this.logo;
            }
            this.addToken(
                this.stakedNet,
                this.stakedAddress,
                this.stakedSymbol,
                this.decimals,
                logo
            );
        },
    },
    mounted() {
        let i = 0;
        this.interval = interval.every(
            () => {
                this.updateAddresses(this.addresses);
                if (this.token.network.networkId === 1) {
                    // If mainnet token, update prices every 100 iterations
                    if (i === 100) {
                        i = 0;
                        getPrice(this.token.address).then((it) => {
                            this.token.price = it;
                        });
                    } else {
                        ++i;
                    }
                }
            },
            15000,
            false
        );
        this.api
            .locksDumbapps(
                this.token.address,
                this.token.network.networkId,
                this.token.stakedAddress,
                this.token.stakedNetwork.networkId
            )
            .then((it) => (this.token.dumbapps = it));
        getPrice(this.token.address).then((it) => {
            this.token.price = it;
        });

        if (this.icon) {
            document.querySelector("link[rel~='icon']").href = this.icon;
        }
    },
    destroyed() {
        this.clear();
    },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;600;700&display=swap");

.super-wrapper {
    font-family: var(--font-family-body-text);
}
</style>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

img.heading-logo {
    height: 50px;

    @media screen and (max-width: 640px) {
        height: 30px;
    }
}

.heading-token-name {
    margin: 0;
    font-weight: bold;
}

.heading-token-address {
    font-family: "Source Sans Pro", sans-serif;


    @media screen and (max-width: 880px) {
        font-size: 14px;
    }
}

.heading-token-name,
.heading-token-address {
    @media screen and (max-width: 640px) {
        display: none;
    }
}

.add-addresses {
    max-width: 600px;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    ::v-deep( h3 ) {
        font-size: 32px;
    }
}

.address-input {
    margin-bottom: 0;
}

.address-input-wrapper {
    display: flex;
    align-items: stretch;
}

.add-metamask {
    margin-top: 15px;
    display: flex;
    align-items: center;

    > ::v-deep(p) {
        margin: 5px 10px;
        text-align: center;
        color: var(--secondary);
    }
}

.locks {
    max-width: 960px;
    margin: 0 auto;
}

.add-buttons {
    display: flex;
    justify-content: space-around;

    button.btn {
        min-width: 50px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.resources-heading {
    margin-bottom: 5px;
}

.resources-nav {
    padding: 0;
    margin: 0;
    list-style: none;

    .nav-item {
        padding: 0;
        margin: 0 0 5px;
    }
}
</style>
