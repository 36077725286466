<template>

    <div class="lock-account">
        <div class="lock-address">
            <span v-if="isUserAccount" class="blockwell-wallet-notice">Blockwell Wallet</span>
            <span v-else class="remove-account" @click="$emit('remove', acc.address)">
                <i class="material-icons">close</i>
            </span>
            <div class="wallet">Wallet</div>
            <i class="material-icons">account_balance_wallet</i>
            <Clipboard :address="acc.address" ellipsize/>
        </div>
        <div class="account-info">
            <div class="available">
                <span class="available-label">
                    Available Balance
                    <Info>
                        This is your token balance excluding tokens that are locked.
                    </Info>
                </span>
                <div class="balance-wrap">
                    <div class="balance-numbers">
                        <TokenAmount :value="acc.availableBalance" symbol=""/>
                        <div v-if="usdBalance" class="balance-usd">
                            <span class="usd-symbol">$</span>
                            <span class="usd-amount">{{ usdBalance }}</span>
                        </div>
                    </div>
                    <div class="balance-symbol">{{ token.symbol }}</div>
                </div>
            </div>
            <InfoSection v-if="hardStaking" class="staking first-box">
                <template #title>
                    Staking
                    <Info>
                        Tokens are
                        <strong>Staked</strong> by swapping them to {{ token.stakedNetwork.networkName }}. Once staked
                        they can be used gas-free on {{ token.stakedNetwork.networkName }}, and earn staking rewards.
                    </Info>
                </template>
                <InfoField label="Staked Tokens" usd :value="acc.staked ? acc.staked.balance : '0'"
                           :symbol="token.stakedSymbol"/>

                <InfoField v-if="hardStaking && hardStaking.nft">
                    <template #label>
                        NFT Detected
                        <Info>
                            The Lepricon NFT Collection increases your APR when held in the same wallet as
                            your L3P tokens. You can
                            <a href="https://opensea.io/collection/lepricon-nft-collection" target="_blank">
                                browse the collection on OpenSea</a>.
                        </Info>
                    </template>
                    <a v-if="stakingNft && stakingNft.link" :href="stakingNft.link" target="_blank">
                        {{stakingNft.name}}
                    </a>
                    <template v-else>
                        {{stakingNft ? stakingNft.name : "None"}}
                    </template>

                    <template v-if="stakingNft && stakingNft.effect" #secondary>
                        {{stakingNft.effect}}
                    </template>
                </InfoField>

                <InfoField v-if="acc.staked" label="Next Staking Reward" usd :value="acc.staked.nextReward"
                           :symbol="token.stakedSymbol">
                    <template slot="secondary">
                        {{ hardStaking.time.format('lll') }}
                    </template>
                </InfoField>
                <InfoField v-if="hardStaking && hardStaking.nft" label="Published APR">
                    {{ hardStakingStandardRate }}%
                </InfoField>
                <InfoField v-if="hardStaking" label="Your APR">
                    {{ hardStakingRate }}%
                    <template slot="secondary">
                        Annual Rate Paid {{ hardStaking.period === "daily" ? "Daily" : "Weekly" }}
                        <br>
                        {{ hardStaking.compounding ? 'With Compounding' : 'No Compounding' }}
                    </template>
                </InfoField>
                <div class="buttons">
                    <DumbappPopover v-if="acc.availableBalance.gt(0)"
                                    :instance="token.dumbapps.stake"
                                    :require-wallet-address="acc.address"
                                    strategy="auth"
                                    label="Stake Tokens"
                    />
                    <DumbappPopover v-if="acc.staked && acc.staked.balance.gt(0)"
                                    :instance="token.dumbapps.unstake"
                                    :require-wallet-address="acc.address"
                                    strategy="auth"
                                    button-class="btn-primary-muted"
                                    label="Unstake"
                    />
                </div>
            </InfoSection>
            <InfoSection v-if="acc.futureSoftTotal && acc.futureSoftTotal.gt(0)" class="soft-staking"
                         :class="hardStaking ? '' : 'first-box'">
                <template #title>
                    Soft Staking
                    <Info>
                        <strong>Soft Staking</strong> rewards you for holding unactivated tokens. Unactivated Tokens are tokens that
                        were locked, but the lock has expired.
                    </Info>
                </template>
                <InfoField label="Unactivated Tokens" :value="acc.softTotal" usd/>
                <InfoField v-if="acc.nextReward" label="Next Reward" :value="acc.nextReward" usd>
                    <template slot="secondary">
                        {{ staking.time.format('lll') }}
                    </template>
                </InfoField>
                <InfoField label="Reward Rate">
                    {{ softStakingRate }}%
                    <template slot="secondary">
                        Annual Rate Paid {{ staking.period === "daily" ? "Daily" : "Weekly" }}
                        <br>
                        {{ staking.compounding ? 'With Compounding' : 'No Compounding' }}
                    </template>
                </InfoField>
            </InfoSection>
            <InfoSection class="locked-tokens"
                         :class="hardStaking || acc.futureSoftTotal ? '' : 'first-box'">
                <template #title>
                    Locks
                    <Info>
                        <p>
                            Tokens that are <strong>Locked</strong> cannot be spent in any way until the lock
                            expires.
                        </p>
                        <p>
                            Locks can expire <strong>gradually</strong> over time, as a kind of token drip,
                            or <strong>all at once</strong>.
                            <template v-if="locks.length > 0">
                                <a href="javascript: void 0;">View Lock Details</a>
                                to see which kinds of locks you have.
                            </template>
                        </p>
                        <p>
                            <strong>Unactivated</strong> tokens are tokens that were previously Locked, but the lock
                            has not been cleared from the blockchain yet.
                        </p>
                        <div>
                            Transferring tokens will activate them automatically, but to use a
                            <strong>DEX, such as Uniswap</strong>,
                            you will need to manually
                            <template v-if="acc.softTotal.gt(0)">
                                <a href="javascript: void 0;" @click="showActivate = true">Activate</a>
                            </template>
                            <template v-else>
                                Activate
                            </template>
                            them first.
                        </div>
                    </Info>
                </template>
                <InfoField label="Tokens Locked" usd :value="acc.lockedTotal"/>
                <InfoField v-if="acc.nextUnlock" label="Next Unlock" :value="acc.nextUnlock.value" usd>
                    <template v-if="acc.nextUnlock.timestamp" #secondary>
                        {{ acc.nextUnlock.timestamp.fromNow() }}
                    </template>
                </InfoField>
                <InfoField :label="'Total ' + token.symbol + ' In Wallet'" usd :value="acc.balance"/>
                <InfoField label="Unactivated" usd :value="acc.softTotal"/>

                <div class="buttons">
                    <DumbappPopover v-if="acc.softTotal.gt(0)"
                                    :instance="token.dumbapps.activate"
                                    :require-wallet-address="acc.address"
                                    strategy="auth"
                                    :show.sync="showActivate">
                        <template #trigger>
                            <button type="button" class="btn btn-sm btn-primary" @click="activateWarning">
                                Activate Tokens
                            </button>
                        </template>
                    </DumbappPopover>

                    <button v-if="locks.length > 0" class="btn btn-sm btn-link" @click="expanded = !expanded">
                        {{ expanded ? 'Hide' : 'View' }} Lock Details
                    </button>
                </div>
                <fade-slide>
                    <div v-if="expanded" class="lock-details">
                        <div v-for="lock in locks" class="asset-lock">
                            <div class="lock-amount">
                                <i class="material-icons">lock</i>
                                <TokenAmount :value="lock.value"/>
                                Locked
                            </div>
                            <div class="lock-unlocks">
                                <template v-if="lock.partial">
                                    <span class="partial-amount">
                                        <TokenAmount :value="lock.partial"/>
                                    </span>
                                    can be activated on
                                    <span>{{ lock.expiration.format('lll') }}</span>, and then again every
                                    <span>{{ lock.periodLengthFormatted }}</span> until fully activated on {{ lock.fullExpiration.format('lll') }}.
                                </template>
                                <template v-else-if="lock.expiration">
                                    can be activated on <span>{{ lock.expiration.format('lll') }}.</span>
                                </template>
                                <template v-else>
                                    can be activated right now.
                                </template>
                            </div>
                        </div>
                    </div>
                </fade-slide>
            </InfoSection>

        </div>

        <template v-if="showActivateWarning">
            <Modal show>
                <p>
                    <strong>Note!</strong> Activating your tokens will stop Soft Staking for your currently
                    Unactivated tokens.
                </p>
                <button class="btn btn-sm btn-outline-danger" @click="showActivateWarning = false">Cancel</button>
                <button class="btn btn-sm btn-primary" @click="activate">OK</button>
            </Modal>
        </template>
    </div>
</template>

<script>
import {getNetwork} from "@/assets/lib/networks";
import Clipboard from "@/components/Clipboard";
import { Dumbapp } from "@blockwell/dumbapp";
import Eip1193Mixin from "@/components/dumbapp/executor/Eip1193Mixin";
import DumbappPopover from "@/components/DumbappPopover";
import FadeSlide from "@/components/FadeSlide";
import Info from "@/components/Info";
import Modal from "@/components/Modal";
import TokenAmount from "@/components/TokenAmount";
import * as interval from "@/lib/interval";
import InfoField from "@/views/happs/locks/InfoField";
import InfoSection from "@/views/happs/locks/InfoSection";
import {sendUnlock} from "@/views/happs/locks/lockslib";
import { LockUser, StakingData } from "@/views/happs/locks/lockstypes";

export default {
    name: "LockAccount",
    components: {Modal, Info, InfoSection, InfoField, DumbappPopover, FadeSlide, TokenAmount, Clipboard},
    mixins: [Eip1193Mixin],
    props: {
        acc: LockUser,
        staking: StakingData,
        hardStaking: StakingData
    },
    inject: ['token'],
    data() {
        return {
            expanded: false,
            fromNow: this.acc?.nextUnlock?.timestamp?.fromNow(),
            showActivateWarning: false,
            showActivate: false
        };
    },
    computed: {
        canUnlock() {
            if (this.acc.softTotal?.gt(0)) {
                return this.acc.softTotal;
            }
            return null;
        },
        correctNetwork() {
            if (this.network && this.token.network) {
                return this.network.networkId === this.token.network.networkId;
            }
            return false;
        },
        netName() {
            return this.token?.network?.networkName;
        },
        locks() {
            return this.acc.locks?.filter(it => !!it.expiration);
        },
        usdBalance() {
            if (this.token.price) {
                return this.token.price.times(this.acc.availableBalance).div(`1e${this.token.decimals}`).toFormat(2);
            }
            return null;
        },
        hardStakingStandardRate() {
            return this.hardStaking.reward?.times(100)?.toString();
        },
        hardStakingRate() {
            if (this.acc.hardStakingRate) {
                return this.acc.hardStakingRate.times(100).toString();
            }
            return this.hardStaking.reward?.times(100)?.toString();
        },
        stakingNft() {
            if (this.acc.nfts?.[0]) {
                return this.acc.nfts[0];
            }
            return null;
        },
        softStakingRate() {
            if (this.acc.softStakingRate) {
                return this.acc.softStakingRate.times(100).toString();
            }
            return this.staking.reward?.times(100)?.toString();
        },
        userAccount() {
            return this.$store.state.user.account;
        },
        isUserAccount() {
            return this.userAccount?.toLowerCase() === this.acc.address.toLowerCase();
        }
    },
    methods: {
        expand() {
            this.expanded = !this.expanded;
        },
        clear() {
            if (this.interval !== null) {
                this.interval.clear();
                this.interval = null;
            }
        },
        unlock() {
            sendUnlock(this.ethereumHolder.ethereum, this.acc.address, this.token.address)
                .catch(console.error);
        },
        activateWarning() {
            this.showActivateWarning = true;
        },
        activate() {
            this.showActivateWarning = false;
            this.showActivate = true;
        }
    },
    mounted() {
        this.interval = interval.every(() => {
            this.fromNow = this.acc?.nextUnlock?.timestamp?.fromNow();
        }, 1000);
    },
    destroyed() {
        this.clear();
    }
}
</script>

<style scoped lang=scss>
@import "~@/assets/css/custom.scss";

.lock-account {

    position: relative;

    ::v-deep( span.token-symbol ) {
        color: var(--secondary);
    }
}

.lock-address {
    background: var(--wallet-heading-background);
    padding: 8px 16px 16px;
    color: var(--wallet-heading-text);
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 20px;

    .wallet {
        flex-basis: 100%;
        padding-left: 28px;
    }

    .material-icons {
        color: var(--wallet-heading-text);
        margin-right: 15px;
        font-size: 24px;

        @media screen and (max-width: 560px) {
            font-size: 18px;
            margin-right: 8px;
        }
    }

    ::v-deep( a ) {
        color: var(--wallet-heading-text);
    }

    ::v-deep( .clipboard-icon ) {
        color: var(--wallet-heading-text);
        fill: var(--wallet-heading-text);
    }

    ::v-deep( .ellipsize ) {
        max-width: none;

        @media screen and (max-width: 620px) {
            font-size: 18px;
        }
        @media screen and (max-width: 460px) {
            max-width: calc(100vw - 80px)
        }
        @media screen and (max-width: 500px) {

        }
        @media screen and (max-width: 400px) {

        }
    }
}

.account-info {
    display: grid;
    grid-template-columns: 1fr 1fr;

    grid-gap: 20px;

    @media screen and (max-width: 780px) {
        display: block;
    }
}

.balance-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.balance-numbers {
    text-align: right;
}

.balance-symbol {
    margin-left: 10px;
    color: var(--secondary);
}

.balance-usd {
    font-size: 16px;
    color: var(--secondary);
}

.first-box {
    grid-column: 1;
    grid-row: 1;
}

.buttons {
    display: flex;
    justify-content: center;
    margin: 10px;

    ::v-deep( .btn ) {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.available {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    margin-bottom: 12px;
    padding: 40px 5px;
}

.available-label {
    color: var(--text-muted);
    font-size: 16px;
    margin-right: 5px;
}

.locked-label {
    font-weight: 600;
    color: var(--text-muted);
    margin-right: 12px;
}

.next-unlock {
    flex-grow: 1;

    .token-amount {
        font-weight: 600;
    }
}

.asset-lock {
    padding-top: 15px;
}

.lock-amount {
    font-weight: 600;
}

.lock-unlocks {
    line-height: 1.2;
    margin-top: 5px;
    margin-left: 20px;
    font-size: 0.9em;
    color: var(--secondary);

    ::v-deep( span ) {
        font-weight: 600;
    }
}

.remove-account {
    background: none;
    border: none;
    padding: 5px 0;
    cursor: pointer;
    color: var(--wallet-heading-text);
    font-weight: normal;
    position: absolute;
    top: 15px;
    right: 0;

    @media screen and (max-width: 780px) {
        top: 0;
    }
}

.blockwell-wallet-notice {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 14px;
    font-weight: normal;
}

.bottom {
    flex: 0 0 100%;
    display: flex;
}

.can-unlock {
    flex: 0 0 100%;
    text-align: center;
    margin: 8px 0 24px;
}

.can-unlock-amount {
    font-size: 20px;
    font-weight: 600;
}

.wrong-account, .wrong-network {
    font-weight: bold;
}

.next-reward {
    ::v-deep( .token-amount ) {
        font-weight: bold;
    }
}

.lock-account ::v-deep() {
    .info-popover .material-icons {
        color: var(--primary-muted);
    }
}
</style>
