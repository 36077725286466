import Vue from "vue";
import { PrimeAllSettings } from "@/lib/prime";
import { mapSettings } from "@blockwell/book/lib/prime";
export default Vue.extend({
    props: {},
    data() {
        return {
            settings: null,
            settingsAddress: null,
            settingsChain: null,
        };
    },
    computed: {
        bookHelper() {
            return this.$store.getters["user/bookHelper"];
        },
        settingsPrime() {
            return {
                address: this.settingsAddress,
                chain: this.settingsChain,
            };
        },
    },
    watch: {
        settingsPrime: {
            deep: true,
            immediate: true,
            handler(val) {
                let books = this.bookHelper;
                if (val.address && val.chain) {
                    books
                        .getPrimeSettings(val.chain, val.address, PrimeAllSettings)
                        .then((settings) => {
                        this.settings = settings;
                    });
                }
            },
        },
    },
    methods: {
        reloadSettings() {
            if (this.settingsPrime.address && this.settingsPrime.chain) {
                let books = this.bookHelper;
                books
                    .getPrimeSettings(this.settingsPrime.chain, this.settingsPrime.addres, PrimeAllSettings, true)
                    .then((settings) => {
                    this.settings = mapSettings(settings);
                });
            }
        },
    },
    created() {
        this.$bus.on("reload-settings", this.reloadSettings);
    },
    destroyed() {
        this.$bus.off("reload-settings", this.reloadSettings);
    },
});
