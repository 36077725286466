import { getNetwork } from "@/assets/lib/networks";
import Clipboard from "@/components/Clipboard.vue";
import Eip1193Mixin from "@/components/dumbapp/executor/Eip1193Mixin";
import FadeSlide from "@/components/FadeSlide.vue";
import Modal from "@/components/Modal.vue";
import TokenAmount from "@/components/TokenAmount.vue";
import { getPrice } from "@/lib/prices";
import { loadMultichainToken, } from "@/views/happs/multichain/multichain";
import MultichainAccount from "@/views/happs/multichain/MultichainAccount.vue";
import { clone } from "rambdax";
import { mapGetters, mapMutations } from "vuex";
import * as interval from "@/lib/interval";
import equal from "fast-deep-equal";
import { getMultichainStaking } from "./multichain-staking";
import WhitelabelHeading from "@/layouts/WhitelabelHeading.vue";
import Heading from "@/layouts/Heading.vue";
import Vue from "vue";
import StyleableLayout from "@/layouts/StyleableLayout.vue";
import SettingsMixin from "@/components/whitelabel/SettingsMixin";
export default Vue.extend({
    components: { StyleableLayout, MultichainAccount, Modal, FadeSlide, TokenAmount, Clipboard },
    mixins: [Eip1193Mixin, SettingsMixin],
    props: {
        chains: {
            type: Array,
        },
        name: String,
        symbol: String,
        decimals: Number,
        logo: String,
        places: Number,
        dumbapp: String,
        stakingBook: String,
        headingLogo: String,
        icon: String,
        whitelabel: Boolean,
        external: String,
        velvet: String,
        whitelabelLayout: {
            type: Boolean,
        },
        cssVars: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    provide() {
        return {
            token: this.token,
        };
    },
    data() {
        return {
            addressInput: "",
            accounts: [],
            interval: null,
            metamaskRequest: false,
            staking: null,
            currentAddresses: null,
            showMetamaskNotice: false,
            token: {
                address: this.chains[0]?.address,
                network: getNetwork(this.chains[0]?.net),
                name: this.name,
                symbol: this.symbol,
                decimals: this.decimals,
                logo: this.logo,
                places: this.places,
                price: null,
                dumbapps: {},
            },
        };
    },
    computed: {
        ...mapGetters("locks", ["scopedAddresses"]),
        api() {
            return this.$store.getters["user/api"];
        },
        loggedIn() {
            return this.$store.getters["user/loggedIn"];
        },
        addresses() {
            return this.scopedAddresses
                .filter((it) => it.scope === this.symbol)
                .map((it) => it.address);
        },
        userAccount() {
            return this.$store.state.user.account;
        },
        tokens() {
            return this.chains.map((it) => {
                let network = getNetwork(it.net);
                return {
                    address: it.address,
                    network,
                    chainId: network.chainId,
                    staking: it.staking,
                    suggestions: it.suggestions,
                    name: this.name,
                    symbol: this.symbol,
                    decimals: this.decimals,
                };
            });
        },
        layoutComponent() {
            if (this.whitelabelLayoutComponent) {
                return this.whitelabelLayoutComponent;
            }
            if (this.whitelabel) {
                return WhitelabelHeading;
            }
            else {
                return Heading;
            }
        },
        velvetLink() {
            if (this.velvet) {
                return `https://blockwell.ai/learn-tokens/token/${this.velvet}`;
            }
            return null;
        },
        dumbappsLink() {
            return `https://app.blockwell.ai/bapps?contract=${this.chains[0].address}&display=grid`;
        }
    },
    watch: {
        addresses: {
            deep: true,
            immediate: true,
            handler(val) {
                if (!equal(val, this.currentAddresses)) {
                    this.currentAddresses = clone(val);
                    this.updateAddresses(val);
                }
            },
        },
        account(val) {
            if (this.metamaskRequest) {
                this.addAddress(val);
                this.metamaskRequest = false;
            }
        },
        chains: {
            deep: true,
            immediate: true,
            handler(chains) {
                let registryChain = chains.find((it) => it.registry);
                if (registryChain) {
                    this.settingsAddress = registryChain.address;
                    this.settingsChain = getNetwork(registryChain.net).chainId;
                }
                else {
                    this.settingsAddress = null;
                    this.settingsChain = null;
                }
            },
        }
    },
    methods: {
        ...mapMutations("locks", ["remove_address", "add_address"]),
        addAddress(address) {
            this.add_address({
                address,
                scope: this.symbol,
            });
            let y = this.$refs.locks.getBoundingClientRect().top + window.pageYOffset - 60;
            window.scrollTo({ top: y, behavior: "smooth" });
            this.$router.replace({ query: { address } });
        },
        removeAddress(address) {
            this.remove_address({
                address,
                scope: this.symbol,
            });
            if (this.$route.query.address?.toLowerCase() === address.toLowerCase()) {
                this.$router.replace({ query: {} });
            }
        },
        submitAddress() {
            this.addAddress(this.addressInput);
            this.addressInput = "";
        },
        keydown(event) {
            if (event.key === "Enter") {
                this.submitAddress();
            }
        },
        updateAddresses(val) {
            let addresses = [].concat(val || []);
            if (this.userAccount) {
                if (!addresses.find((it) => it.toLowerCase() === this.userAccount.toLowerCase())) {
                    addresses.unshift(this.userAccount);
                }
            }
            if (this.$route.query.address) {
                if (!addresses.find((it) => it.toLowerCase() === this.$route.query.address.toLowerCase())) {
                    addresses.unshift(this.$route.query.address);
                }
            }
            if (addresses.length > 0) {
                Promise.all(this.tokens.map((it) => loadMultichainToken(it.address, it.network.chainId, addresses)))
                    .then((res) => {
                    let accounts = [];
                    let i = 0;
                    for (let address of addresses) {
                        let account = {
                            address,
                            tokens: res.map((it, index) => {
                                return {
                                    token: this.tokens[index],
                                    data: it[i],
                                };
                            }),
                        };
                        accounts.push(account);
                        ++i;
                    }
                    this.accounts = accounts;
                })
                    .catch(console.error);
            }
            else {
                this.accounts = [];
            }
        },
        clear() {
            if (this.interval !== null) {
                this.interval.clear();
                this.interval = null;
            }
        },
        fromMetamask() {
            if (this.account) {
                this.addAddress(this.account);
            }
            else {
                if (this.ethereum) {
                    this.metamaskRequest = true;
                    try {
                        this.connect();
                    }
                    catch (err) {
                        console.error(err);
                        this.showMetamaskNotice = true;
                    }
                }
                else {
                    this.showMetamaskNotice = true;
                }
            }
        },
        login() {
            this.$bus.emit("login_request", {
                message: "",
            });
        },
    },
    mounted() {
        let i = 0;
        this.interval = interval.every(() => {
            this.updateAddresses(this.addresses);
            if (this.token.network.networkId === 1) {
                // If mainnet token, update prices every 100 iterations
                if (i === 100) {
                    i = 0;
                    getPrice(this.token.address).then((it) => {
                        this.token.price = it;
                    });
                }
                else {
                    ++i;
                }
            }
        }, 15000, false);
        this.api
            .multichainDumbapps(this.tokens.map((it) => {
            return {
                address: it.address,
                chainId: it.network.chainId,
                staking: !!it.staking,
            };
        }))
            .then((it) => (this.token.dumbapps = it));
        getPrice(this.token.address).then((it) => {
            this.token.price = it;
        });
        if (this.stakingBook) {
            getMultichainStaking(this.stakingBook).then((it) => (this.staking = it));
        }
        if (this.icon) {
            let element = document.querySelector("link[rel~='icon']");
            if (element instanceof HTMLAnchorElement) {
                element.href = this.icon;
            }
        }
    },
    destroyed() {
        this.clear();
    },
});
